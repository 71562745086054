class UniqueIdUtils {
  static SERIAL_BIT = BigInt(4);
  static NODE_BIT = BigInt(4);
  static TIME_STAMP_BIT = BigInt(41);
  static START_TIME_POINT = new Date(2019, 6, 1, 0, 0, 0);
  static FLAG = BigInt(0);
  static LAST_TIME_STAMP = -1;
  static NODE_ID = BigInt(0);
  static SERIAL = BigInt(0);
  static SERIAL_MASK = ~(BigInt(-1) << this.SERIAL_BIT);

  static uniqueId() {
    const current_timestamp = BigInt(Date.now() - this.START_TIME_POINT.getTime());
    if (this.LAST_TIME_STAMP == current_timestamp) {
      // 同一毫秒，序列号递增
      const temp = (this.SERIAL + BigInt(1)) & this.SERIAL_MASK;
      if (temp == 0) {
        // 当前秒内序列已满，重新获取
        setTimeout(function () {}, 1);
        return this.uniqueId();
      }
      this.SERIAL = temp;
    } else {
      this.LAST_TIME_STAMP = current_timestamp;
      this.SERIAL = BigInt(0);
    }
    const id =
      this.SERIAL |
      (this.NODE_ID << this.SERIAL_BIT) |
      (this.LAST_TIME_STAMP << (this.SERIAL_BIT + this.NODE_BIT)) |
      (this.FLAG << (this.SERIAL_BIT + this.NODE_BIT + this.TIME_STAMP_BIT));
    return id;
  }
}

export const UniqueId = UniqueIdUtils;

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class LocalStorage {
  static prefix = "__LocalStorage__";
  static set(key, value) {
    const data = {
      data: value,
    };
    localStorage.setItem(this.prefix + key, JSON.stringify(data));
  }
  static get(key) {
    const data = localStorage.getItem(this.prefix + key);
    return data ? JSON.parse(data).data : undefined;
  }
  static remove(key) {
    localStorage.removeItem(this.prefix + key);
  }
}
export const LocalStorageService = LocalStorage;

const { ethers } = require("ethers");
export function formatEther(value, decimal = 18, precise = 5) {
  let strValue = String(value);
  if (strValue.length < decimal) {
    strValue = "0".repeat(decimal - strValue.length + 1) + strValue;
  }
  const suffix = strValue.slice(-decimal).slice(0, precise);
  const prefix = strValue.slice(0, strValue.length - decimal);
  return parseFloat(String(prefix) + "." + String(suffix));
}

export function formatAddress(address) {
  address = ethers.utils.getAddress(address);
  const prefix = address.slice(0, 6);
  const suffix = address.slice(-4);
  return prefix + "..." + suffix;
}

const { MerkleTree } = require("merkletreejs");
export function genMerkleRoot(addressList) {
  let walletList = Array.from(new Set(addressList));
  walletList.sort();
  walletList = walletList.map((wallet) => ethers.utils.getAddress(wallet));
  const leafList = walletList.map((wallet) => ethers.utils.keccak256(wallet));
  const merkleTree = new MerkleTree(leafList, ethers.utils.keccak256, { sort: true });
  return merkleTree.getHexRoot();
}

export function getMerkleProof(addressList, address) {
  let walletList = Array.from(new Set(addressList));
  walletList.sort();
  walletList = walletList.map((wallet) => ethers.utils.getAddress(wallet));
  const leafList = walletList.map((wallet) => ethers.utils.keccak256(wallet));
  const merkleTree = new MerkleTree(leafList, ethers.utils.keccak256, { sort: true });
  return merkleTree.getHexProof(ethers.utils.keccak256(address));
}

export function formatDate(dateObject) {
  const yyyy = dateObject.getFullYear();
  const mm = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  const dd = ("0" + dateObject.getDate()).slice(-2);
  const hh = dateObject.getHours();
  const min = ("0" + dateObject.getMinutes()).slice(-2);
  const ss = ("0" + dateObject.getSeconds()).slice(-2);
  return yyyy + "-" + mm + "-" + dd + " " + hh + ":" + min + ":" + ss;
}
